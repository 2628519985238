<!-- [팝업] 이미지 리소스 업로드 -->
<template>
  <b-modal
    v-model="showImageResourceFileUpload"
    cancel-variant="outline-secondary"
    centered
    ok-title="업로드"
    cancel-title="취소"
    size="lg"
    title="이미지 리소스 업로드"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-form-group
          label="이미지 유형"
          label-for="image-resource-file-upload"
        >
          <b-form-radio-group
            v-model="query.imageResourceType"
            :options="codes.imageResourceType"
            value-field="code"
            text-field="label"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-form-group
          label="이미지 리소스 파일"
          label-for="image-resource-files"
        >
          <b-form-file
            multiple
            placeholder="5MB 이하의 JPG, JPEG, PNG 파일만 지원합니다."
            :state="!isFileBlocked"
            :file-name-formatter="formatNames"
            :accept="acceptFileExt"
            @input="inputFiles"
          />
        </b-form-group></b-col></b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <div
          v-for="(item, idx) in imageResourceDataList"
          :key="idx"
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text style="width: 20rem;">
                {{ item.ImageResourceFileName }}
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              v-model="item.Title"
              placeholder="이용 목적을 입력해주세요 (최대 250자)"
              maxlength="250"
            />
            <b-input-group-append>
              <b-input-group-text
                style="width: 6rem;"
              >
                {{ (item.ImageResourceFileSize / (1024 * 1024)).toFixed(2) }} MB
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </div>
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import axios from '@axios'
import { ref, onMounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BFormFile, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BInputGroupText,
} from 'bootstrap-vue'

export default {
  components: {
    BFormFile,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroupText,
  },

  props: {
    showImageResourceFileUpload: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    onMounted(() => {
      fetchImageResourceType()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const acceptFileExt = ".jpg, .jpeg, .png"
    const imageResourceDataList = ref([])
    const isFileBlocked = ref(false)

    // Set Codes
    const codes = ref({
      imageResourceType: [],
    })

    const fetchImageResourceType = () => {
      axios.get(`/fa/imageResource/codes`)
        .then(rs => {
          codes.value.imageResourceType = rs.data.imageResourceType
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }
    // Set Codes End.

    // Query Data
    const query = ref({
      imageResourceType: 1, // 1: 약품정보
    })
    // Query Data End.

    // File config
    const imageResourceFiles = ref([]) // 이미지 첨부파일 목록
    // File config End.

    // #TODO: 다른 확장자 경고를 여기서 띄워주도록 개선 가능
    const formatNames = () => {
      if (isFileBlocked.value) {
        return `5MB 이하의 JPG, JPEG, PNG 파일만 지원합니다.`
      }
      return `5MB 이하의 JPG, JPEG, PNG 파일만 지원합니다.`
    }

    const inputFiles = files => {
      const filteredFiles = files
        .filter(file => file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg') || file.name.toLowerCase().endsWith('.png'))
        .filter(image => image.size < 5248122)

      // 파일이 필터링된 경우 업로드 조건 강조를 해제
      if (filteredFiles.length < files.length) {
        isFileBlocked.value = true
      } else {
        isFileBlocked.value = false
      }

      // 이미 선택된 파일과 중복되지 않도록 추가
      const newFiles = filteredFiles.filter(file => !imageResourceFiles.value.some(existingFile => existingFile.name === file.name))

      // 새로운 파일이 있는 경우에만 추가
      if (newFiles.length > 0) {
        imageResourceFiles.value = [...imageResourceFiles.value, ...newFiles]

        // imageResourceDataList 채우기
        for (let i = 0; i < newFiles.length; i += 1) {
          imageResourceDataList.value.push({
            ImageId: 0,
            ImageResourceType: query.value.imageResourceType,
            Title: '',
            ImageResourceFileName: newFiles[i].name,
            ImageResourceFileSize: newFiles[i].size,
          })
        }
      }
    }

    const resetImageData = () => {
      query.value.imageResourceType = 1
      imageResourceDataList.value = []
      imageResourceFiles.value = []
    }

    const uploadImage = () => {
      const formdata = new FormData()

      // console.log(imageResourceFiles.value)

      formdata.append("jsonImageResourceUploadList", JSON.stringify(imageResourceDataList.value))

      for (let i = 0; i < imageResourceFiles.value.length; i += 1) {
        formdata.append("imageResourceFileList", imageResourceFiles.value[i])
      }

      axios.post("/fa/imageResource", formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(rs => {
          imageResourceDataList.value = []
          if (rs) {
            pushToast('success', '이미지 업로드 완료')
            emit('close', 1)
            resetImageData()
          }
        })
        .catch(() => {
          imageResourceDataList.value = []
          pushToast('danger', '이미지를 업로드하는 과정에서 오류가 발생하였습니다.')
          emit('close', null)
        })
    }

    const close = () => {
      resetImageData()
      emit('close', null)
    }

    const submit = () => {
      if (imageResourceFiles.value === null || imageResourceFiles.value.length === 0 || imageResourceDataList.value.length === 0) {
        toast({
          component: ToastificationContent,
          props: {
            title: '업로드할 이미지를 선택하세요',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }
      uploadImage()
    }

    return {
      query,
      codes,
      close,
      submit,
      uploadImage,
      imageResourceFiles,
      resetImageData,
      inputFiles,
      acceptFileExt,
      formatNames,
      imageResourceDataList,
      isFileBlocked,
    }
  },
}
</script>
