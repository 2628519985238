<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-end"
          >
            <v-select
              v-model="query.filterType"
              :options="codes.imageResourceType"
              placeholder="컨텐츠 유형"
              :reduce="option => option.code"
              class="el-def"
              @change="refetchData()"
            />

            <b-form-input
              v-model="query.filterStr"
              class="el-def"
              placeholder="이용 목적"
              @keyup.enter="refetchData()"
            />

            <v-date-picker
              v-model="query.searchDate"
              type="month"
              value-type="YYYYMM"
              class="el-def"
              style="width: 150px"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              조회 된 이미지 [{{ totalRecords }}] 건
            </h5>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-primary"
              class="btn-search"
              @click.prevent="showImageResourceFileUpload = true"
            >
              <feather-icon
                icon="UploadIcon"
                class="mr-50"
              />
              <span>이미지 업로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refImageTable"
        primary-key="imageId"
        :items="fetchImages"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        class="position-relative"
        style="max-height: 670px"
      >
        <template #cell(imageResourceType)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ codes.imageResourceType[data.value-1].label }}
          </div>
        </template>

        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>

        <template #cell(filePath)="data">
          <span class="text-nowrap">
            <b-button
              variant="primary"
              @click="copyToClipboard(data.value)"
            >
              URL 복사
            </b-button>
          </span>
        </template>

        <template #cell(userName)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>

      </b-table>
    </b-card>

    <image-resource-file-upload
      :show-image-resource-file-upload="showImageResourceFileUpload"
      @close="imageResourceFileUpload.close"
    />
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import vSelect from 'vue-select'
import { BButton } from 'bootstrap-vue'
import {
  ref, onMounted,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, requiredIf, before, dateBetween,
} from '@validations'
import ImageResourceFileUpload from './ImageResourceFileUpload.vue'

export default {
  components: {
    vSelect,
    ImageResourceFileUpload,
    BButton,
  },
  setup() {
    onMounted(() => {
      fetchImageType()
    })

    const previewUrl = `${process.env.VUE_APP_PREVIEW_URL}/#/app/preview`
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const cleave = ref({
      point: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        prefix: 'P',
        tailPrefix: true,
      },
      person: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      percent: {
        numeral: true,
        prefix: '%',
        tailPrefix: true,
      },
    })

    const showImageResourceFileUpload = ref(false)
    const imageResourceFileUpload = {
      close: rs => {
        if (rs) {
          refetchData()
        }
        showImageResourceFileUpload.value = false
      },
    }

    // Set Codes
    const codes = ref({
      imageResourceType: [],
    })
    // Set Codes End.

    // Query Data
    const query = ref({
      filterType: null,
      filterStr: null,
      searchDate: dayjs().format('YYYYMM'),
    })
    // Query Data End.

    // Main Table Config
    const refImageTable = ref(null)

    const sortBy = ref('createDate')
    const isSortDesc = ref(true)
    const totalRecords = ref(0)
    const tableColumns = [
      {
        key: 'imageResourceType', label: '컨텐츠 유형', sortable: true, thStyle: { width: '10%' },
      },
      {
        key: 'title', label: '이용 목적', sortable: true,
      },
      {
        key: 'fileName', label: '파일명', sortable: true, thStyle: { width: '20%' },
      },
      {
        key: 'filePath', label: 'URL', sortable: true, thStyle: { width: '15%' },
      },
      {
        key: 'createUserName', label: '등록자', sortable: true, thStyle: { width: '10%' },
      },
      {
        key: 'createDate', label: '등록일시', sortable: true, thStyle: { width: '14%' },
      },
    ]

    // Fetch Data
    const refetchData = () => {
      query.value.searchDate = query.value.searchDate ? query.value.searchDate : dayjs().format('YYYYMM')
      refImageTable.value.refresh()
    }

    const fetchImageType = () => {
      axios.get(`/fa/imageResource/codes`)
        .then(rs => {
          codes.value.imageResourceType = rs.data.imageResourceType
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }

    const fetchImages = (ctx, callback) => {
      axios.post('fa/imageResource/list', {
        search: {
          filterType: query.value.filterType,
          filterStr: query.value.filterStr,
          searchDate: query.value.searchDate ? dayjs(query.value.searchDate).date(1).format('YYYY-MM-DD') : null,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data

          callback(items)

          totalRecords.value = totalRecord

          // resetImageData()
        })
        .catch(() => {
          pushToast('danger', '데이터 목록 갱신에 실패하였습니다.')
        })
    }
    // Fetch Data End.

    const copyToClipboard = text => {
      if (!text) {
        this.pushToast('error', 'URL이 없습니다.')
        return
      }
      navigator.clipboard.writeText(text)
        .then(() => {
          pushToast('success', `URL이 클립보드에 복사되었습니다.`)
        })
        .catch(err => {
          console.log(err)
          pushToast('warning', `클립보드에 복사하는 동안 오류가 발생했습니다.`)
        })
    }

    return {
      query,
      codes,
      tableColumns,
      refImageTable,
      sortBy,
      isSortDesc,
      required,
      requiredIf,
      before,
      dateBetween,
      refetchData,
      totalRecords,
      fetchImages,
      fetchImageType,
      cleave,
      previewUrl,
      showImageResourceFileUpload,
      imageResourceFileUpload,
      copyToClipboard,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
#eventPreview .modal-dialog {
  max-width: 1304px;
}
</style>
